import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import onChairClick from '../../actions/action_onChairClick';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import './featured.css';

import feat1 from './images/feat1.jpg';
import feat2 from './images/feat2.jpg';
import feat3 from './images/feat3.jpg';
import feat4 from './images/feat4.jpg';

class Featured extends Component {
  render() {
    return (
      <section id="featured">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 feat-img">
              <AnchorLink offset="50" href="#main-product-section" onClick={() => this.props.onChairClick(this.props.chairs.vip.name, "diagonal", this.props.chaircolors.ivory, this.props.bowlcolors.pearl, "white", this.props.chairs.vip.msrp)}>
                <img className="img-fluid" src={feat1} alt={feat1} />
              </AnchorLink>
            </div>
            <div className="col-md-3 feat-img">
              <AnchorLink offset="50" href="#main-product-section" onClick={() => this.props.onChairClick(this.props.chairs.novo.name, "diagonal", this.props.chaircolors.fossilgrey, this.props.bowlcolors.mocha, "", this.props.chairs.novo.msrp)}>
                <img className="img-fluid" src={feat2} alt={feat2} />
              </AnchorLink>
            </div>
            <div className="col-md-3 feat-img">
              <AnchorLink offset="50" href="#main-product-section" onClick={() => this.props.onChairClick(this.props.chairs.tergo.name, "diagonal", this.props.chaircolors.burgundy, this.props.bowlcolors.pearl, "", this.props.chairs.tergo.msrp)}>
                <img className="img-fluid" src={feat3} alt={feat3} />
              </AnchorLink>
            </div>
            <div className="col-md-3 feat-img">
              <AnchorLink offset="50" href="#main-product-section" onClick={() => this.props.onChairClick(this.props.chairs.magna.name, "diagonal", this.props.chaircolors.ivory, this.props.bowlcolors.rosegold, "", this.props.chairs.magna.msrp)}>
                <img className="img-fluid" src={feat4} alt={feat4} />
              </AnchorLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
}


function mapStateToProps(state) {
  return {
    chairs: state.chairs,
    chaircolors: state.chaircolors,
    bowlcolors: state.bowlcolors,
    accessories: state.accessories,
    accessoriesURLs: state.accessoriesURLs,
    selection: state.selection
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onChairClick: onChairClick }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Featured);
