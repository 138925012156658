import React from 'react';

import { FaFacebookSquare } from 'react-icons/lib/fa';

import './FacebookAuthBtn.css';

const FacebookAuthBtn = () => {
  return (
    <div>
      <a id="FacebookAuthBtn" className="" href="auth/facebook"><FaFacebookSquare id="FacebookAuthIcon" alt="Facebook Icon" />Continue With Facebook</a>
    </div>
  );
}

export default FacebookAuthBtn;