
import { ACCESSORIES_CLICK } from '../actions/action_onAccessoriesClick';

let initialState = {
  category: "chairs",
  product: 'customerchair',
  angle: 'front',
  productName: 'customer chair',
  chaircolor: 'ivory',
  pictureURL: 'images/Accessories/chairs/customerchair/medium/front/customerchair-ivory.png',
  hidden: false,
  texture: ""
}

export default function (state = initialState, action) {
  // console.log("Action received", action);
  // console.log("state", state);
  // console.log("action", action.type);
  // console.log("action.payload", action.payload);
  switch (action.type) {
    case ACCESSORIES_CLICK:
      return action.payload;
    default:
      return state;
  }
}
