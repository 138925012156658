import axios from 'axios';
import { FETCH_USER } from './types';

// USING ASYNC AWAIT SYNTAX
export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/current_user');
  dispatch({ type: FETCH_USER, payload: res.data });
};

// // SAME EXPORT USING PROMISES SYNTAX
// export default fetchUser = () => {
//   return function(dispatch) {
//     axios
//       .get('/api/current_user')
//       .then(res => dispatch({ type: FETCH_USER, payload: res.data }));
//   }
// };