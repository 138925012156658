import React from 'react';

import './notification.css';

import newlocation from './images/newlocation.jpg';

const Features = () => {
  return (
    <section id="notification">
      <div className="container-fluid">
        <div className="row">
          <div className=" noti-img">
            <img className="img-fluid" src={newlocation} alt={newlocation} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;