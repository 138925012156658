import React from 'react';

import './trends.css';

import trends from './images/trends.jpg';

const Trends = () => {
  return (
    <section id="trends">
      <img className="img-fluid trends-img" src={trends} alt={trends} />
    </section>
  );
}

export default Trends;