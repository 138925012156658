import { CHAIR_CLICK } from '../actions/action_onChairClick';
import { CHAIR_COLOR_CLICK } from '../actions/action_onChairColorClick';

let initialState = { 
  product: 'magna', 
  angle: 'diagonal',
  chaircolor: 'ivory', 
  bowlcolor: 'graphite',
  basecolor: '',
  pictureURL: 'images/SpaChair/magna/medium/diagonal/magna-ivory-graphite.png', 
  msrp: '$4,395' 
}

export default function(state = initialState, action) {
  // console.log("Action received", action);
  // console.log("state", state);
  // console.log("action", action.type);
  // console.log("action.payload", action.payload);
  switch (action.type) {
    case CHAIR_CLICK:
      return action.payload;
    case CHAIR_COLOR_CLICK:
      return action.payload;
    default:
      return state;
  }
}
