import React from 'react';

import './nomagnets.css';

const Nomagnets = () => {
  return (
    <section id="nomagnets">
      <div className="container">
        <h1 className="nomagnets-title">No Magnetic Jets</h1>
        <h3 className="nomagnets-subtitle">Jet-Liners&trade; Are The Next Generation of Pedicure Spa Jets</h3>
        <p className="nomagnets-description">Our technology can protect you from nasty foot infections caused by using magnetic jets. If Magnetic Jets are not cleaned properly, cross-contamination of bacteria from the previous client can still exist in your footbath. With our Patented Jet-Liner&trade; technology, we stand alone as the only comprehensive solution. Our Jet-Liners are changed out each time. See these videos to see stories of people who have gotten infections from pedicures using magnetic jets:</p>      
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <iframe title="news1" className="nomagnets-video" src="https://assets.scrippsdigital.com/cms/videoIframe.html?&host=www.theindychannel.com&title=Woman%20claims%20she%20got%20a%20serious%20foot%20infection%20after%20pedicure%20at%20a%20Noblesville%20spa&uid=2944afc4252f4b67b23462b34bd556e1&purl=/news/local-news/hamilton-county/woman-claims-she-got-a-serious-foot-infection-after-pedicure-at-a-noblesville-spa&story=1&ex=1&s=wrtv" frameBorder="0" allowFullScreen></iframe>
              <div className="card-body">
                <h5 className="card-title">Woman hospitalized with foot infection days after getting pedicure at Noblesville spa.</h5>
                <p className="card-text">Why take the risk with Magnetic Jets? Ask for Contego Jet-Liners&trade;.</p>
                <a href="https://www.theindychannel.com/news/local-news/hamilton-county/woman-claims-she-got-a-serious-foot-infection-after-pedicure-at-a-noblesville-spa"  target="_blank" rel="noopener noreferrer" className="btn btn-primary nomagnets-link">See Full Story</a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <iframe title="news2" className="nomagnets-video" src="https://abcnews.go.com/video/embed?id=22827746" frameBorder="0" allowFullScreen></iframe>
              <div className="card-body">
                <h5 className="card-title">ABC News goes undercover to reveal what you are really sinking your hands and feet into at some salons.</h5>
                <p className="card-text">Why take the risk with Magnetic Jets? Ask for Contego Jet-Liners&trade;.</p>
                <a href="https://abcnews.go.com/WNT/video/mani-pedi-health-22827746" target="_blank" rel="noopener noreferrer" className="btn btn-primary nomagnets-link">See Full Story</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Nomagnets;