import React from 'react';

import './videos.css';

import Nomagnets from './nomagnets/nomagnets';

const Videos = () => {
  return (
    <section id="videos">
      <Nomagnets />
      <div id="main-videos" className="container-fluid">
      <h1 className="video-header">English</h1>
        <div className="row">
          <div id="video1" className="col-md-6 video-box">
            <iframe className="video-item" title="Contego Spa Designs Intro To JetLiner™ For Pedicure Spa Chair" src="https://www.youtube.com/embed/-DDksqVPw1U" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div id="video3" className="col-md-6 video-box">
            <iframe className="video-item" title="IPA Approves Contego Chairs" src="https://www.youtube.com/embed/CIhTNawXC7U?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>
      <h1 className="video-header">Vietnamese</h1>
        <div className="row">
          <div id="video1" className="col-md-6 video-box">
            <iframe className="video-item" title="Contego Spa Chair New Features" src="https://www.youtube.com/embed/LeEMO3F61vQ?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div id="video2" className="col-md-6 video-box">
            <iframe className="video-item" title="Contego Spa Designs Chair Features in Vietnamese" src="https://www.youtube.com/embed/jUcum9Uc5iw?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>        
      <h1 className="video-header">Trade Shows</h1>
        <div className="row">
          <div id="video1" className="col-md-3 video-box">
            <iframe className="video-item" title="Contego Spa ISSE Long Beach 2018" src="https://www.youtube.com/embed/SUPnognzWSE?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div id="video2" className="col-md-3 video-box">
            <iframe className="video-item" title="San Jose Tet Festival 2018" src="https://www.youtube.com/embed/XANJ-_72mg8?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div id="video3" className="col-md-3 video-box">
            <iframe className="video-item" title="Contégo Spa Designs at Image Expo in Houston 2016" src="https://www.youtube.com/embed/NiuUfjQr6qc?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div id="video4" className="col-md-3 video-box">
            <iframe className="video-item" title="Contego Spa Designs at Nail Pro in Pasadena 2016" src="https://www.youtube.com/embed/CdWQL47nX44?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>          
      </div>
    </section>
  );
}

export default Videos;