import React from 'react';
import { FaMapMarker, FaPhone, FaEnvelope, FaClockO, FaFacebook, FaYoutube, FaInstagram, FaYelp } from 'react-icons/lib/fa';

import './footer.css';

import logo from './images/logo-white-small.png';

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">

          <div className="row">

            <div className="footer-widget-col col-md-3">
              <div className="widget widget_text">
                <div className="textwidget">
                  <h6><strong>Contact</strong></h6>
                  <p><FaPhone className="fa fa-phone"></FaPhone> <a href="tel:714-895-8865">714-895-8865</a></p>
                  <p><FaEnvelope className="fa fa-envelope"></FaEnvelope> <a href="mailto:contact@contegospa.com">contact@contegospa.com</a></p>
                </div>
              </div>
            </div>          

            <div className="footer-widget-col col-md-3">
              <div className="widget">
                <div className="textwidget">
                  <h6>Administrative</h6>
                  <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/bD6dyksdoRE2" rel="noopener noreferrer" target="_blank">12856 Brookhurst St, Garden Grove, CA 92840 USA</a></p>
                  <p><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Sun: 9:00am - 6:00pm</p>
                </div>
              </div>
            </div>

            <div className="footer-widget-col col-md-3">
              <div className="widget">
                <div className="textwidget">
                  <h6>Showroom - Garden Grove</h6>
                  <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/bD6dyksdoRE2" rel="noopener noreferrer" target="_blank">12856 Brookhurst St, Garden Grove, CA 92840 USA</a></p>
                  <p><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Sun: 9:00am - 6:00pm</p>
                </div>
              </div>
              <div className="widget">
                <div className="textwidget">
                  <h6>Showroom - Houston</h6>
                  <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/mJtYPcC76pT2" rel="noopener noreferrer" target="_blank">6787A Wilcrest Dr, Houston, TX 77072 USA</a></p>
                  <p style={{marginBottom: "0px"}}><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Thur: 10:00am - 5:00pm</p>
                  <p><FaClockO className="fa fa-clock-o"></FaClockO> Sun: 1:00pm - 6:00pm</p>
                </div>
              </div>
            </div>

            {/* <div className="footer-widget-col col-md-3">
              <div className="widget">
                <div className="textwidget">
                  <h6><strong>ShowRoom</strong></h6>
                  <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/PPH8bRsxcKn" rel="noopener noreferrer" target="_blank">14044 Magnolia St., STE #227-228 Garden Grove, CA 92844 USA</a></p>
                  <p><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Sun: 10:00am - 6:00pm</p>
                </div>
              </div>
            </div>             */}

            <div className="footer-widget-col col-md-3">
              <div className="social-btns">
                <a className="btn facebook" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/contegospa/"><FaFacebook className="fa fa-facebook" /></a>
                <a className="btn youtube" rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UC7kqMnIleOcvV76jwmNb7Rg"><FaYoutube className="fa fa-youtube"></FaYoutube></a>
                <a className="btn instagram" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/contegospa/"><FaInstagram className="fa fa-instagram"></FaInstagram></a>
                <a className="btn yelp" rel="noopener noreferrer" target="_blank" href="https://www.yelp.com/biz/contego-spa-designs-westminster"><FaYelp className="fa fa-yelp"></FaYelp></a>
              </div>
            </div>

          </div>

          <hr />

          <div className="row justify-content-between">
            <div className="col-5">
              <img src={logo} alt={logo} height="25" />
            </div>
            <div className="col-7">
              <p className="copyright">&copy; {(new Date().getFullYear())} Contego Spa Designs, Inc. All Rights Reserved.</p>
            </div>
          </div>

      </div>



    </footer>
  );
}

export default Footer;