import React from 'react';

import Hero from './hero/hero';
import Notification from './notification/notification';
import Featured from '../../containers/featured/featured';
import Vs from './vs/vs';
import Trends from './trends/trends';
import Features from './features/features';
import Products from '../../containers/products/products';
// import Testimonials from './testimonials/testimonials';
import Videosfp from './videosfp/videosfp';
// import Quote from './quote/quote';
// import Affiliate from './affiliate/affiliate';

const Home = () => {
  return (
    <main>
      <Hero />
      <Notification />
      <Featured />
      <Vs />
      <Trends />
      <Features />
      <Products />
      {/*<Testimonials />*/}
      <Videosfp />
      {/*<Quote />*/}
      {/* <Affiliate /> */}
    </main>
  );
}

export default Home;