import React from 'react';

import './downloads.css';

import novoUserManual from './images/novo-user-manual.png';
import spec from './images/spec.png'
import unpack from './images/unpack.png';

import novomanual from './files/NOVO-Pedicure-Spa-UserManual-V1.4.pdf';
import novospecifications from './files/ContegoSystemDiagram_August2018.pdf';

const Downloads = () => {
  return (
    <section id="downloads">
      <h1 className="dl-title">Downloads</h1>
      <div id="downloads-cards" className="container">
        
        <div className="row">

          <div className="col-md-6 dl-container">
            <div className="card">
              <img className="card-img-top" src={novoUserManual} alt={novoUserManual} />
              <div className="card-body">
                <h5 className="card-title">Novo Pedi-Spa Chair User Manual</h5>
                <p className="card-text">The Novo Pedi-Spa chair User Manual including safety information, specifications, instalation, operation instructions, and more.</p>
                <a href={novomanual} download rel="noopener noreferrer" target="_blank" className="btn btn-primary">Download</a>
              </div>
            </div>
          </div>

          <div className="col-md-6 dl-container">
            <div className="card">
              <img className="card-img-top" src={spec} alt={spec} />
              <div className="card-body">
                <h5 className="card-title">Novo Pedi-Spa Chair Specifications</h5>
                <p className="card-text">All the technical details you need on the Novo Pedi-Spa chair including shipping dimensions, chair dimensions, and instructional diagrams on plumbing and electrical.</p>
                <a href={novospecifications} download rel="noopener noreferrer" target="_blank" className="btn btn-primary">Download</a>
              </div>
            </div>
          </div>

        </div>


        <div className="row">

          <div className="col-md-6 dl-container">
            <div className="card">
              <img className="card-img-top" src={unpack} alt={unpack} />
              <div className="card-body">
                <h5 className="card-title">Unpacking & Hookup Procedures</h5>
                <p className="card-text">If you’re receiving a shipment, this has all the proper procedures for unpacking your pallet and hooking up your new Pedi-Spa chair.</p>
                <a href="https://www.dropbox.com/s/qkeury1fsqhoitq/Unpacking%26HookupProcedure.pdf?dl=0" rel="noopener noreferrer" target="_blank" className="btn btn-primary">Download</a>
              </div>
            </div>
          </div>


        </div>

      </div>
    </section>
  );
} 

export default Downloads;