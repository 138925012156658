import React from 'react';

import './jet.css';

import contegovs from './images/contego-vs.jpg';

const Jet = () => {
  return (
    <section id="jet">
      <div className="container">
        <h1 className="jet-title">Our Patented Technology</h1>
        <h3 className="jet-subtitle">The World's Only Disposable Pedicure Jet<sup>&trade;</sup></h3>
        <img className="img-fluid" src={contegovs} alt={contegovs} />
      </div>
    </section>
  );
}

export default Jet;