import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery.css';

// import img01 from './images/gallery/01.jpg';
// import img02 from './images/gallery/02.jpg';
// import img03 from './images/gallery/03.jpg';
// import img04 from './images/gallery/04.jpg';
// import img05 from './images/gallery/05.jpg';
// import img06 from './images/gallery/06.jpg';
// import img07 from './images/gallery/07.jpg';
// import img08 from './images/gallery/08.jpg';
// import img09 from './images/gallery/09.jpg';
// import img10 from './images/gallery/10.jpg';
// import img11 from './images/gallery/11.jpg';
// import img12 from './images/gallery/12.jpg';
// import img13 from './images/gallery/13.jpg';
// import img14 from './images/gallery/14.jpg';
// import img15 from './images/gallery/15.jpg';
// import img16 from './images/gallery/16.jpg';
// import img17 from './images/gallery/17.jpg';
// import img18 from './images/gallery/18.jpg';
// import img19 from './images/gallery/19.jpg';
// import img20 from './images/gallery/20.jpg';
// import img21 from './images/gallery/21.jpg';
// import img22 from './images/gallery/22.jpg';
// import img23 from './images/gallery/23.jpg';
// import img24 from './images/gallery/24.jpg';
// import img25 from './images/gallery/25.jpg';
// import img26 from './images/gallery/26.jpg';
// import img27 from './images/gallery/27.jpg';
// import img28 from './images/gallery/28.jpg';
// import img29 from './images/gallery/29.jpg';
// import img30 from './images/gallery/30.jpg';
// import img31 from './images/gallery/31.jpg';
// import img32 from './images/gallery/32.jpg';
// import img33 from './images/gallery/33.jpg';
// import img34 from './images/gallery/34.jpg';
// import img35 from './images/gallery/35.jpg';
// import img36 from './images/gallery/36.jpg';
// import img37 from './images/gallery/37.jpg';
// import img38 from './images/gallery/38.jpg';
// import img39 from './images/gallery/39.jpg';
// import img40 from './images/gallery/40.jpg';
// import img41 from './images/gallery/41.jpg';
// import img42 from './images/gallery/42.jpg';

// import thumbimg01 from './images/thumb/01.jpg';
// import thumbimg02 from './images/thumb/02.jpg';
// import thumbimg03 from './images/thumb/03.jpg';
// import thumbimg04 from './images/thumb/04.jpg';
// import thumbimg05 from './images/thumb/05.jpg';
// import thumbimg06 from './images/thumb/06.jpg';
// import thumbimg07 from './images/thumb/07.jpg';
// import thumbimg08 from './images/thumb/08.jpg';
// import thumbimg09 from './images/thumb/09.jpg';
// import thumbimg10 from './images/thumb/10.jpg';
// import thumbimg11 from './images/thumb/11.jpg';
// import thumbimg12 from './images/thumb/12.jpg';
// import thumbimg13 from './images/thumb/13.jpg';
// import thumbimg14 from './images/thumb/14.jpg';
// import thumbimg15 from './images/thumb/15.jpg';
// import thumbimg16 from './images/thumb/16.jpg';
// import thumbimg17 from './images/thumb/17.jpg';
// import thumbimg18 from './images/thumb/18.jpg';
// import thumbimg19 from './images/thumb/19.jpg';
// import thumbimg20 from './images/thumb/20.jpg';
// import thumbimg21 from './images/thumb/21.jpg';
// import thumbimg22 from './images/thumb/22.jpg';
// import thumbimg23 from './images/thumb/23.jpg';
// import thumbimg24 from './images/thumb/24.jpg';
// import thumbimg25 from './images/thumb/25.jpg';
// import thumbimg26 from './images/thumb/26.jpg';
// import thumbimg27 from './images/thumb/27.jpg';
// import thumbimg28 from './images/thumb/28.jpg';
// import thumbimg29 from './images/thumb/29.jpg';
// import thumbimg30 from './images/thumb/30.jpg';
// import thumbimg31 from './images/thumb/31.jpg';
// import thumbimg32 from './images/thumb/32.jpg';
// import thumbimg33 from './images/thumb/33.jpg';
// import thumbimg34 from './images/thumb/34.jpg';
// import thumbimg35 from './images/thumb/35.jpg';
// import thumbimg36 from './images/thumb/36.jpg';
// import thumbimg37 from './images/thumb/37.jpg';
// import thumbimg38 from './images/thumb/38.jpg';
// import thumbimg39 from './images/thumb/39.jpg';
// import thumbimg40 from './images/thumb/40.jpg';
// import thumbimg41 from './images/thumb/41.jpg';
// import thumbimg42 from './images/thumb/42.jpg';

class Gallery extends Component {
  render() {


    const images = [
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/01.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/01.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/02.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/02.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/03.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/03.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/04.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/04.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/05.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/05.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/06.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/06.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/07.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/07.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/08.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/08.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/09.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/09.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/10.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/10.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/11.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/11.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/12.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/12.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/13.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/13.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/14.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/14.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/15.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/15.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/16.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/16.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/17.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/17.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/18.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/18.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/19.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/19.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/20.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/20.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/21.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/21.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/22.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/22.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/23.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/23.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/24.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/24.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/25.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/25.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/26.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/26.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/27.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/27.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/28.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/28.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/29.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/29.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/30.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/30.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/31.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/31.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/32.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/32.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/33.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/33.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/34.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/34.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/35.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/35.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/36.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/36.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/37.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/37.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/38.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/38.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/39.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/39.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/40.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/40.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/41.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/41.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/42.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/42.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/43.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/43.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/44.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/44.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/45.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/45.jpg'
      },
      {
        original: process.env.PUBLIC_URL + './images/gallery/gallery/46.jpg',
        thumbnail: process.env.PUBLIC_URL + './images/gallery/thumb/46.jpg'
      }
    ]

    return (
      <section id="gallery">
        <div className="container">
          <h1 className="gallery-title">Gallery</h1>
          <ImageGallery items={images} />
        </div>
      </section>
    );
  }
}

export default Gallery;