import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalImage from 'react-modal-image';

import onChairClick from '../../actions/action_onChairClick';
import onChairColorClick from '../../actions/action_onChairColorClick';

import './products.css';

import magna from './images/magna.png';
import vip from './images/vip.png';
import tergo from './images/tergo.png';
import novo from './images/novo.png';
import doubleVip from './images/vipdouble-ivory-pearl.png';
import tripleVip from './images/viptriple-ivory-pearl.png';

import gold from './images/gold-medium.jpg';
import rosegold from './images/rosegold-medium.jpg';
import pearl from './images/pearl-medium.jpg';
import graphite from './images/graphite-medium.jpg';
import mocha from './images/mocha-medium.jpg';

import seal from './images/seal.png';

import Accessories from './accessories/accessories';


class Products extends Component {
  render() {
    return (
      <section id="products">   
        <div id="products-main"></div>
        <h1 className="display-4 title">Contego Pedicure Spa Chairs</h1>
        <div className="container">
          <div className="row">
            <div  id="width-max-lg" className="col-md">
              <p className="lead-products">Select Chair</p>
              <div className="row chair-selection-section">
                <div className="col-6 col-custom">
                  <button className="select-chair-button" onClick={() => this.props.onChairClick(this.props.chairs.magna.name, this.props.selection.angle, this.props.selection.chaircolor, this.props.selection.bowlcolor, '', this.props.chairs.magna.msrp)}>
                    <div className="chair-name">{this.props.chairs.magna.name}</div>
                    <img className="chair-img" src={magna} alt={magna} width="100" />
                  </button>
                  <button className="select-chair-button" onClick={() => this.props.onChairClick(this.props.chairs.vip.name, this.props.selection.angle, this.props.selection.chaircolor, this.props.selection.bowlcolor, 'white', this.props.chairs.vip.msrp)}>
                    <div className="chair-name">{this.props.chairs.vip.name}</div>
                    <img className="chair-img" src={vip} alt={vip} width="100" />
                  </button>
                </div>
                <div className="col-6 col-custom">
                  <button className="select-chair-button" onClick={() => this.props.onChairClick(this.props.chairs.tergo.name, this.props.selection.angle, this.props.selection.chaircolor, this.props.selection.bowlcolor, '', this.props.chairs.tergo.msrp)}>
                    <div className="chair-name">{this.props.chairs.tergo.name}</div>
                    <img className="chair-img" src={tergo} alt={tergo} width="100" />
                  </button>
                  <button className="select-chair-button" onClick={() => this.props.onChairClick(this.props.chairs.novo.name, this.props.selection.angle, this.props.selection.chaircolor, this.props.selection.bowlcolor, '', this.props.chairs.novo.msrp)}>
                    <div className="chair-name">{this.props.chairs.novo.name}</div>
                    <img className="chair-img" src={novo} alt={novo} width="100" />
                  </button>
                </div>
                <div className="col-md-12">
                  <button className="select-chair-button" onClick={() => this.props.onChairClick(this.props.chairs.vip.name + "x2", this.props.selection.angle, "ivory", "pearl", '', this.props.chairs.vip2x.msrp)}>
                    <div className="chair-name">{this.props.chairs.vip.name} x2</div>
                    <img className="chair-img" src={doubleVip} alt={doubleVip} height="129" />
                </button>
                </div>
                <div className="col-md-12">
                  <button className="select-chair-button" onClick={() => this.props.onChairClick(this.props.chairs.vip.name + "x3", this.props.selection.angle, "ivory", "pearl", '', this.props.chairs.vip3x.msrp)}>
                    <div className="chair-name">{this.props.chairs.vip.name} x3</div>
                    <img className="chair-img" src={tripleVip} alt={tripleVip} height="129" />
                  </button>
                </div>
              </div>
    
            </div>


            <div id="main-product-section" className="col-md">
              <p className="lead-products">{this.props.selection.product}</p>
              <img src={seal} alt={seal} className="seal" />
              <ModalImage className={this.props.selection.product==='vipx2' || this.props.selection.product==='vipx3' ? "vip-height-mod" : "chair-main-img"} small={process.env.PUBLIC_URL + this.props.selection.pictureURL} large={process.env.PUBLIC_URL + this.props.selection.pictureURL} alt={this.props.selection.product + ' ' + this.props.selection.chaircolor + ' ' + this.props.selection.bowlcolor + ' ' + this.props.selection.basecolor} />
              <p className="lead-legal">*Digital coloring may vary.</p>
              <p className="lead-msrp">MSRP: <strong>{this.props.selection.msrp}</strong></p>
              <div className={this.props.selection.product==='vipx2' || this.props.selection.product==='vipx3' ? 'd-none' : 'row'}>
                <div className="col-4">
                  <button className="product-alt-link" onClick={() => this.props.onChairClick(this.props.selection.product, "diagonal", this.props.selection.chaircolor, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)}>
                    <img className="chair-img" src={process.env.PUBLIC_URL + "/images/SpaChair/" + this.props.selection.product + "/small/diagonal/" + this.props.selection.product + "-" + this.props.selection.chaircolor + "-" + this.props.selection.bowlcolor + this.props.selection.basecolor + ".png"} alt="diagonal" width="38" height="50"/>
                  </button>
                </div>
                <div className="col-4">
                  <button className="product-alt-link" onClick={() => this.props.onChairClick(this.props.selection.product, "side", this.props.selection.chaircolor, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)}>
                    <img className="chair-img" src={process.env.PUBLIC_URL + "/images/SpaChair/" + this.props.selection.product + "/small/side/" + this.props.selection.product + "-" + this.props.selection.chaircolor + "-" + this.props.selection.bowlcolor + this.props.selection.basecolor + ".png"} alt="side" width="38" height="50"/>
                  </button>
                </div>
                <div className="col-4">
                  <button className="product-alt-link" onClick={() => this.props.onChairClick(this.props.selection.product, "front", this.props.selection.chaircolor, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)}>
                    <img className="chair-img" src={process.env.PUBLIC_URL + "/images/SpaChair/" + this.props.selection.product + "/small/front/" + this.props.selection.product + "-" + this.props.selection.chaircolor + "-" + this.props.selection.bowlcolor + this.props.selection.basecolor + ".png"} alt="front" width="38" height="50"/>
                  </button>
                </div>
              </div>
              <div id="basecolors-section" className={this.props.selection.product==='vip' ? '' : 'd-none'}>
                <p className="lead-products">Base Colors</p>
                <div className="row chair-section">
                  <div className="col">
                    <div className="color-title">{this.props.basecolors.black}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.basecolors.black, this.props.selection.msrp)} className="colorbox black"> </button>
                  </div>
                  <div className="line linebase"></div>
                  <div className="col">
                    <div className="color-title">{this.props.basecolors.white}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.basecolors.white, this.props.selection.msrp)} className="colorbox white"> </button>
                  </div>  
                </div>
              </div>
            </div>

            <div id="spachair-colors-display" className={this.props.selection.product==='vipx2' || this.props.selection.product==='vipx3' ? "d-none" : "col-md"}>
                <p className="lead-products">Chair Colors</p>
                <div className="row chair-section">
                  <div className="col">
                    <div className="color-title">{this.props.chaircolors.black}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.black, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox black"> </button>
                    <div className="color-title">{this.props.chaircolors.fossilgrey}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.fossilgrey, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox fossilgrey"> </button>
                    <div className="color-title">{this.props.chaircolors.cappuccino}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.cappuccino, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox cappuccino"> </button>
                  </div>
                  <div className="line linechair"></div>
                  <div className="col">
                    <div className="color-title">{this.props.chaircolors.ivory}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.ivory, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox ivory"> </button>
                    <div className="color-title">{this.props.chaircolors.burgundy}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.burgundy, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox burgundy"> </button>
                    <div className="color-title">{this.props.chaircolors.darkchocolate}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.darkchocolate, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox darkchocolate"> </button>
                  </div>
                </div>
                <p className="lead-products">Bowl Colors</p>
                <div className="row">
                  <div className="col">
                    <div className="color-title">{this.props.bowlcolors.rosegold}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.rosegold, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox rosegold"><img src={rosegold} alt={rosegold} width="70" height="50" /> </button>
                    <div className="color-title">{this.props.bowlcolors.gold}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.gold, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox gold"><img src={gold} alt={gold} width="70" height="50" /> </button>
                    <div className="color-title">{this.props.bowlcolors.pearl}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.pearl, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox pearl"><img src={pearl} alt={pearl} width="70" height="50" /> </button>                
                  </div>
                  <div className="line linebowl"></div>
                  <div className="col">
                    <div className="color-title">{this.props.bowlcolors.graphite}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.graphite, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox graphite"><img src={graphite} alt={graphite} width="70" height="50" /> </button>
                    <div className="color-title">{this.props.bowlcolors.mocha}</div>
                    <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.mocha, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox mocha"><img src={mocha} alt={mocha} width="70" height="50" /> </button>                
                  </div>
                </div>
              </div>
          </div>
        </div>

        <Scrollspy items={ ['main-product-section'] } currentClassName="scrollingOver">
            <section id="color-select-bar" className={this.props.selection.product==='vipx2' || this.props.selection.product==='vipx3' ? "d-none" : "fixed-bottom-custom"}>
              <div id="chaircolor-select-bar" className="navbar-light bg-light">
                <p className="chaircolor-select-bar-title">CHAIR:</p>
                <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.black, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar black"> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.fossilgrey, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar fossilgrey"> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.cappuccino, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar cappuccino"> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.ivory, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar ivory"> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.burgundy, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar burgundy"> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.chaircolors.darkchocolate, this.props.selection.product, this.props.selection.angle, this.props.selection.bowlcolor, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar darkchocolate"> </button>
              </div>
              <div id="bowlcolor-select-bar" className="navbar-light bg-light">
                <p className="bowlcolor-select-bar-title">BOWL:</p>
                <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.rosegold, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar rosegold"><img className="colorbox-bar-img" src={rosegold} alt={rosegold} width="72" height="50" /> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.gold, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar gold"><img className="colorbox-bar-img" src={gold} alt={gold} width="72" height="50" /> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.pearl, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar pearl"><img className="colorbox-bar-img" src={pearl} alt={pearl} width="72" height="50" /> </button>                
                <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.graphite, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar graphite"><img className="colorbox-bar-img" src={graphite} alt={graphite} width="72" height="50" /> </button>
                <button onClick={() => this.props.onChairColorClick(this.props.selection.chaircolor, this.props.selection.product, this.props.selection.angle, this.props.bowlcolors.mocha, this.props.selection.basecolor, this.props.selection.msrp)} className="colorbox-bar mocha"><img className="colorbox-bar-img" src={mocha} alt={mocha} width="72" height="50" /> </button>                
              </div>
            </section>
        </Scrollspy>    


        <Accessories />
      </section>
    );
  }
}





function mapStateToProps(state) {
  return {
    chairs: state.chairs,
    chaircolors: state.chaircolors,
    bowlcolors: state.bowlcolors,
    basecolors: state.basecolors,
    selection: state.selection
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onChairClick: onChairClick, onChairColorClick: onChairColorClick }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);

