export const ACCESSORIES_CLICK = "ACCESSORIES_CLICK";

export default function onAccessoriesClick(category, accessories, angle, accessoriesName, chaircolor, sectionShow, texture) {
  let accessoriesURL = 'images/Accessories/' + category + '/' + accessories + '/medium/' + angle + '/' + accessories + '-' + chaircolor + texture + '.png';
  return {
    type: ACCESSORIES_CLICK,
    payload: {
      category: category,
      product: accessories,
      angle: angle,
      productName: accessoriesName,
      chaircolor: chaircolor,
      pictureURL: accessoriesURL,
      hidden: sectionShow,
      texture: texture
    }
  };
}