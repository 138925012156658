export default function() {
  return {
    magna: {
      name: "magna",
      msrp: "$4,395"
    },
    vip: {
      name: "vip",
      msrp: "$4,995"
    },
    tergo: {
      name: "tergo",
      msrp: "$3,395"
    },
    novo: {
      name: "novo",
      msrp: "$3,195"
    },
    vip2x: {
      name: "vip2x",
      msrp: "$9,990"
    },
    vip3x: {
      name: "vip3x",
      msrp: "$14,985"
    }
  };
}
