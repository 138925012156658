import React from "react";
import ScrollUpButton from "react-scroll-up-button";

import './scrollupbutton.css';
 
export default class ScrollUpButtonClass extends React.Component {
    render() {
        return (
          <ScrollUpButton ContainerClassName="ScrollUpButton__Container" TransitionClassName="ScrollUpButton__Toggled">

          </ScrollUpButton>
        );
    }
}
