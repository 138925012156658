import React from 'react';

import GoogleIcon from './images/googleIcon.png';

import './GoogleAuthBtn.css';

const GoogleAuthBtn = () => {
  return (
    <div>
      <a id="GoogleAuthBtn" className="" href="/auth/google"><img id="GoogleAuthIcon" src={GoogleIcon} alt="Google Icon" />Continue With Google</a>
    </div>
  );
}

export default GoogleAuthBtn;