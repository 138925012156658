import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalImage from 'react-modal-image';

import onAccessoriesClick from '../../../actions/action_onAccessoriesClick';

import './accessories.css';

import customerchair from './images/customerchair.png';
import techchair from './images/techchair.png';
import manistool from './images/manistool.png';
import pedistool from './images/pedistool.png';

import table35in from './images/table35in.png';
import table38in from './images/table38in.png';

class Accessories extends Component {
  render() {
    return (
      <section id="accessories">
        <h1 className="display-4 title accessories-title">Accessories</h1>
        <div className="container">
          <div className="row">

            <div className="col-md">
              <div className="accessories-chairs-section">

                <p className="lead-products">Select Chair</p>
                <div className="row">
                  <div className="col-6 col-custom">
                    <button className="select-chair-button" onClick={() => this.props.onAccessoriesClick("chairs", this.props.accessories.chairs.techchair.id, "front", this.props.accessories.chairs.techchair.name, this.props.accessoriesSelection.chaircolor, false, this.props.accessoriesSelection.texture)}>
                      <img className="chair-img accessories-chairs" src={techchair} alt={this.props.accessories.chairs.techchair + ' ' + this.props.accessoriesSelection.chaircolor} height="125" />
                      <p className="chair-name">Tech Chair</p>
                    </button>
                    <button className="select-chair-button" onClick={() => this.props.onAccessoriesClick("chairs", this.props.accessories.chairs.pedistool.id, "front", this.props.accessories.chairs.pedistool.name, this.props.accessoriesSelection.chaircolor, false, this.props.accessoriesSelection.texture)}>
                      <img className="chair-img accessories-chairs" src={pedistool} alt={this.props.accessories.chairs.pedistool + ' ' + this.props.accessoriesSelection.chaircolor} height="125" />
                      <p className="chair-name">Pedi Stool</p>
                    </button>
                  </div>
                  <div className="col-6 col-custom">
                    <button className="select-chair-button" onClick={() => this.props.onAccessoriesClick("chairs", this.props.accessories.chairs.manistool.id, "front", this.props.accessories.chairs.manistool.name, this.props.accessoriesSelection.chaircolor, false, "")}>
                      <img className="chair-img accessories-chairs" src={manistool} alt={this.props.accessories.chairs.manistool + ' ' + this.props.accessoriesSelection.chaircolor} height="125" />
                      <p className="chair-name">Mani Stool</p>
                    </button>
                    <button className="select-chair-button" onClick={() => this.props.onAccessoriesClick("chairs", this.props.accessories.chairs.customerchair.id, "front", this.props.accessories.chairs.customerchair.name, this.props.accessoriesSelection.chaircolor, false, "")}>
                      <img className="chair-img accessories-chairs" src={customerchair} alt={this.props.accessories.chairs.customerchair + ' ' + this.props.accessoriesSelection.chaircolor} height="125" />
                      <p className="chair-name">Customer Chair</p>
                    </button>
                  </div>
                </div>

                <p className="lead-products lead-products-table">Select Table</p>
                <div className="row">
                  <div className="col-6 col-custom">
                    <button className="select-chair-button" onClick={() => this.props.onAccessoriesClick("tables", this.props.accessories.tables.table35in.id, "front", this.props.accessories.tables.table35in.name, "ivory", true, "")}>
                      <img className="chair-img accessories-tables" src={table35in} alt={this.props.accessories.tables.table35in.name} height="90" />
                      <p className="chair-name">35in Table</p>
                    </button>
                  </div>
                  <div className="col-6 col-custom">
                    <button className="select-chair-button" onClick={() => this.props.onAccessoriesClick("tables", this.props.accessories.tables.table38in.id, "front", this.props.accessories.tables.table38in.name, "ivory", true, "")}>
                      <img className="chair-img accessories-tables" src={table38in} alt={this.props.accessories.tables.table38in.name} height="90" />
                      <p className="chair-name">38in Table</p>
                    </button>
                  </div>
                </div>

              </div>
            </div>
            {/*Section for viewing accessories*/}
            <div id="main-product-accessories-section" className="col-md">
              <p className="lead-products">{this.props.accessoriesSelection.productName}</p>
              <ModalImage small={process.env.PUBLIC_URL + this.props.accessoriesSelection.pictureURL} large={process.env.PUBLIC_URL + this.props.accessoriesSelection.pictureURL} alt={this.props.accessoriesSelection.product + ' ' + this.props.accessoriesSelection.chaircolor + ' ' + this.props.accessoriesSelection.texture} />
              <p className="lead-legal">*Digital coloring may vary.</p>

              {/*section for accessory angle */}
              {/* <div className="row">
                <div className="col-4">
                  <button className="product-alt-link" onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, "diagonal", this.props.accessoriesSelection.productName, this.props.accessoriesSelection.chaircolor, this.props.accessoriesSelection.hidden, this.props.accessoriesSelection.texture)}>
                    <img className="chair-img" src={process.env.PUBLIC_URL + "/images/Accessories/" + this.props.accessoriesSelection.category + "/" + this.props.accessoriesSelection.product + "/small/diagonal/" + this.props.accessoriesSelection.product + "-" + this.props.accessoriesSelection.chaircolor + this.props.accessoriesSelection.texture + ".png"} alt="diagonal" width="38" />
                  </button>
                </div>
                <div className="col-4">
                  <button className="product-alt-link" onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, "side", this.props.accessoriesSelection.productName, this.props.accessoriesSelection.chaircolor, this.props.accessoriesSelection.hidden, this.props.accessoriesSelection.texture)}>
                    <img className="chair-img" src={process.env.PUBLIC_URL + "/images/Accessories/" + this.props.accessoriesSelection.category + "/" + this.props.accessoriesSelection.product + "/small/side/" + this.props.accessoriesSelection.product + "-" + this.props.accessoriesSelection.chaircolor + this.props.accessoriesSelection.texture + ".png"} alt="side" width="38" />
                  </button>
                </div>
                <div className="col-4">
                  <button className="product-alt-link" onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, "front", this.props.accessoriesSelection.productName, this.props.accessoriesSelection.chaircolor, this.props.accessoriesSelection.hidden, this.props.accessoriesSelection.texture)}>
                    <img className="chair-img" src={process.env.PUBLIC_URL + "/images/Accessories/" + this.props.accessoriesSelection.category + "/" + this.props.accessoriesSelection.product + "/small/front/" + this.props.accessoriesSelection.product + "-" + this.props.accessoriesSelection.chaircolor + this.props.accessoriesSelection.texture + ".png"} alt="front" width="38" />
                  </button>
                </div>
              </div> */}

              {/*section for extra options for chairs(tech chair and pedi stool)*/}
              <div id="basecolors-section" className={(this.props.accessoriesSelection.product === 'pedistool' || this.props.accessoriesSelection.product === 'techchair') ? '' : 'd-none'}>
                <p className="lead-products">Texture</p>
                <div className="row chair-section">
                  <div className="col">
                    <div className="color-title">Regular</div>
                    <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.accessoriesSelection.chaircolor, false, "")} className="colorbox white"> </button>
                  </div>                  
                  <div className="line linebase"></div>
                  <div className="col">
                    <div className="color-title">Diamond</div>
                    <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.accessoriesSelection.chaircolor, false, "-diamond")} className="colorbox black"> </button>
                  </div>
                </div>
              </div>

            </div>
            {/*Color Choose Section*/}
            <div id={this.props.accessoriesSelection.hidden ? "" : "accessories-color-display"} className='col-md d-none'>
              <p className="lead-products">Chair Colors</p>
              <div className="row chair-section">
                <div className="col">
                  <div className="color-title">{this.props.chaircolors.black}</div>
                  <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.black, false, this.props.accessoriesSelection.texture)} className="colorbox black"> </button>
                  <div className="color-title">{this.props.chaircolors.fossilgrey}</div>
                  <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.fossilgrey, false, this.props.accessoriesSelection.texture)} className="colorbox fossilgrey"> </button>
                  <div className="color-title">{this.props.chaircolors.cappuccino}</div>
                  <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.cappuccino, false, this.props.accessoriesSelection.texture)} className="colorbox cappuccino"> </button>
                </div>
                <div className="line linechair"></div>
                <div className="col">
                  <div className="color-title">{this.props.chaircolors.ivory}</div>
                  <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.ivory, false, this.props.accessoriesSelection.texture)} className="colorbox ivory"> </button>
                  <div className="color-title">{this.props.chaircolors.burgundy}</div>
                  <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.burgundy, false, this.props.accessoriesSelection.texture)} className="colorbox burgundy"> </button>
                  <div className="color-title">{this.props.chaircolors.darkchocolate}</div>
                  <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.darkchocolate, false, this.props.accessoriesSelection.texture)} className="colorbox darkchocolate"> </button>
                </div>
              </div>
            </div>

          </div>
        </div>


        <Scrollspy items={['main-product-accessories-section']} currentClassName="scrollingOver">
          <section id="color-select-bar" className={this.props.accessoriesSelection.hidden ? "d-none" : "fixed-bottom-custom"}>
            <div id="chaircolor-select-bar" className="navbar-light bg-light">
              <p className="chaircolor-select-bar-title">CHAIR:</p>
              <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.black, false, this.props.accessoriesSelection.texture)} className="colorbox-bar black"> </button>
              <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.fossilgrey, false, this.props.accessoriesSelection.texture)} className="colorbox-bar fossilgrey"> </button>
              <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.cappuccino, false, this.props.accessoriesSelection.texture)} className="colorbox-bar cappuccino"> </button>
              <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.ivory, false, this.props.accessoriesSelection.texture)} className="colorbox-bar ivory"> </button>
              <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.burgundy, false, this.props.accessoriesSelection.texture)} className="colorbox-bar burgundy"> </button>
              <button onClick={() => this.props.onAccessoriesClick(this.props.accessoriesSelection.category, this.props.accessoriesSelection.product, this.props.accessoriesSelection.angle, this.props.accessoriesSelection.productName, this.props.chaircolors.darkchocolate, false, this.props.accessoriesSelection.texture)} className="colorbox-bar darkchocolate"> </button>
            </div>
          </section>
        </Scrollspy>


      </section>
    );
  }
}


function mapStateToProps(state) {
  return {
    chaircolors: state.chaircolors,
    accessories: state.accessories,
    accessoriesSelection: state.accessoriesSelection
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ onAccessoriesClick: onAccessoriesClick }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Accessories);
