import React from 'react';
import ReactDOM from 'react-dom';

import GoogleAuthBtn from './GoogleAuthBtn';
import FacebookAuthBtn from './FacebookAuthBtn';
// import EmailPassAuthForm from './EmailPassAuthForm';

import './AuthModal.css';

const AuthModal = (props) => {
  // console.log(props);
  return ReactDOM.createPortal(
    <div className={`authmodal modal fade ${(props.modalShow ? 'show' : 'hide')}`} tabIndex="-1" role="dialog" onClick={props.handleModalHide}>
      <div className="authmodal-dialog modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
        <div className="authmodal-content modal-content">
          <div className="authmodal-header modal-header">
            <h5 className="authmodal-title modal-title">Sign Up or Log In</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.handleModalHide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="authmodal-body modal-body">
            <GoogleAuthBtn />
            <FacebookAuthBtn />
            {/* <div className="auth-divider">
              or
            </div>
            <EmailPassAuthForm /> */}
          </div>
          <div className="authmodal-footer modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={props.handleModalHide}>Close</button>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}

export default AuthModal;