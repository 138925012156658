export const CHAIR_CLICK = "CHAIR_CLICK";

export default function onChairClick(chair, angle, chaircolor, bowlcolor, basecolor, msrp) {
  let chairURL = "images/SpaChair/" + chair + "/medium/" + angle + "/" + chair + "-" + chaircolor + "-" + bowlcolor + basecolor + ".png";
  return {
    type: CHAIR_CLICK,
    payload: {
      product: chair,
      angle: angle,
      chaircolor: chaircolor,
      bowlcolor: bowlcolor,
      basecolor: basecolor,
      pictureURL: chairURL,
      msrp: msrp
    }
  };
}
