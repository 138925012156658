import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './actions';
import './App.css';

import Header from './components/header/header';
import Home from './components/home/home';
import About from './components/about/about';
import Contact from './components/contact/contact';
import Faq from './components/faq/faq';
import Downloads from './components/downloads/downloads';
import Gallery from './components/gallery/gallery';
import Videos from './components/videos/videos';
import Footer from './components/footer/footer';

import ShoppingCart from './containers/shoppingcart/shoppingcart';
import Thankyou from './components/home/quote/thankyou/thankyou';

import Socialmedia from './components/socialmedia/socialmedia';
import ScrollUpButtonClass from './utilities/scrollupbutton/scrollupbutton';

import ScrollToTop from './utilities/scrolltotop/scrolltotop';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-129574197-1');
ReactGA.pageview('/');

class App extends Component {

  componentDidMount() {
    const user = this.props.fetchUser();
    console.log(user);
  }

  renderContent() {
    // console.log('rendercontent');
  }

  render() {
    // console.log(this.props);
    return (
      <Router>
        <ScrollToTop>
          <div className="App">
            <Header />
            <Socialmedia />
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/faq" component={Faq} />
            <Route path="/downloads" component={Downloads} />
            <Route path="/gallery" component={Gallery} />
            <Route path="/videos" component={Videos} />
            <Route path="/cart" component={ShoppingCart} />
            <Route path="/thankyou" component={Thankyou} />
            <ScrollUpButtonClass />
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = (auth) => {
  // console.log(auth);
  return { auth }
}

export default connect(mapStateToProps, actions)(App);
