import React, { Component } from 'react';

import Nav from './nav';

import './header.css';

export default class Header extends Component {
  render() {
    return (
      <header id="header">
        <Nav />
      </header>
    );
  }
}