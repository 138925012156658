import React, { Component } from 'react';
// import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { HashLink as Link, NavHashLink as NavLink } from 'react-router-hash-link';

import { FaCaretDown } from 'react-icons/lib/fa';

import AuthModal from '../auth/AuthModal';


// import { Navbar, NavbarBrand, NavbarNav, NavbarToggler, Collapse, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
// import { BrowserRouter as Router } from 'react-router-dom';

import logo from './images/logo-full-small.png';

import './nav.css';

class Nav extends Component {
  state = {
    isCollapsed: true,
    isShow: false,
    isAriaexpanded: false,
    modalShow: false
  };

  handleMoreMenuClick = () => {
    this.setState({
        isCollapsed: false,
        isShow: true,
        isAriaexpanded: true
    });
  }

  handleHideMenuClick = () => {
    this.setState({
        isCollapsed: true,
        isShow: false,
        isAriaexpanded: false
    });
  }

  handleModalHide = () => {
    this.setState({ modalShow: false });
  }

  handleModalShow = () => {
    this.setState({ modalShow: true });
  }

  renderContent = () => {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return (
          <React.Fragment>
            <button type="button" id="nav-quote" className="" data-toggle="modal" data-target="#exampleModal" onClick={this.handleModalShow}>
              Sign Up/Login
            </button>
            <AuthModal 
                modalShow={this.state.modalShow} 
                handleModalShow={this.handleModalShow} 
                handleModalHide={this.handleModalHide}  
              />
          </React.Fragment>
        );
        // return <a id="nav-quote" className="" href="/auth/google" onClick={this.handleHideMenuClick}>Login<AuthModal show /></a>;
      default: 
        return <a id="nav-quote" className="" href="/api/logout" onClick={this.handleHideMenuClick}>Logout</a>;
    }
  }

  render() {
    // console.log(this.props);
    return (
      <nav id="navbar-custom" className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <Link id="nav-logo" className="navbar-brand" to="/" onClick={this.handleHideMenuClick}><img src={logo} alt={logo} height="25" /></Link>

        <div className="d-flex flex-row">
          <ul className="navbar-nav flex-row">

          </ul>
        </div>

        <button id="navbar-toggler-custom" onClick={this.handleMoreMenuClick} className={"navbar-toggler " + (this.state.isCollapsed ? "collapsed" : "")} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="">MENU<FaCaretDown className="FaCaretDown-custom" /></span>
        </button>

        <div className={"collapse navbar-collapse " + (this.state.isShow ? "show" : "")} id="navbarSupportedContent">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={this.handleHideMenuClick}>Home <span className="sr-only">(current)</span></NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about" onClick={this.handleHideMenuClick}>About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={this.handleHideMenuClick}>Contact</NavLink>
            </li>          
            <li className="nav-item">
              <NavLink className="nav-link" to="/#products-main" onClick={this.handleHideMenuClick}>Pedi-Chairs</NavLink>
            </li>          
            <li className="nav-item">
              <NavLink className="nav-link" to="/faq" onClick={this.handleHideMenuClick}>FAQ</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/downloads" onClick={this.handleHideMenuClick}>Downloads</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/gallery" onClick={this.handleHideMenuClick}>Gallery</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/videos" onClick={this.handleHideMenuClick}>Videos</NavLink>
            </li>            
          </ul>
        </div>

        {/* {this.renderContent()} */}
        {/* <Link id="nav-quote" className="" to="" onClick={this.handleHideMenuClick}>Login</Link> */}

        {/* <Link id="nav-quote" className="" to="/#quote" onClick={this.handleHideMenuClick}>Request Quote</Link> */}
      </nav>
    );
  }
}

function mapStateToProps({auth}) {
  return { auth };
}

export default connect(mapStateToProps)(Nav);