export default function() {
  return {
    chairs: {
      customerchair: {
        id: 'customerchair',
        name: 'customer chair'
      },
      manistool: {
        id: 'manistool',
        name: 'mani stool'
      },
      pedistool: {
        id: 'pedistool',
        name: 'pedi stool'
      },
      techchair: {
        id: 'techchair',
        name: 'tech chair'
      }
    },
    tables: {
      table35in: {
        id: 'table35in',
        name: '35in table'
      },
      table38in: {
        id: 'table38in',
        name: '38in table'
      }
    }
  }
}