import React from 'react';
import { FaMapMarker, FaPhone, FaEnvelope, FaClockO } from 'react-icons/lib/fa';


import './contact.css';

const Contact = () => {
  return (
    <section id="contact">
      <div className="container">
        <h1 className="contact-title">Contact</h1>
        {/* <div className="row mb-5">
          <div className="col-md-4 contact-info">
            <h6><strong>ShowRoom</strong></h6>
            <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/PPH8bRsxcKn" rel="noopener noreferrer" target="_blank">14044 Magnolia St., STE #227-228 Garden Grove, CA 92844 USA</a></p>
            <p><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Sun: 10:00am - 6:00pm</p>
            <p><FaPhone className="fa fa-phone"></FaPhone> <a href="tel:714-895-8865">714-895-8865</a></p>
            <p><FaEnvelope className="fa fa-envelope"></FaEnvelope> <a href="mailto:contact@contegospa.com">contact@contegospa.com</a></p>            
          </div>
          <div className="col-md-8">
            <iframe className="map" title="Map of ShowRoom" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3317.078720982368!2d-117.97430858479264!3d33.75863158068679!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd262ccc17af5f%3A0x473f5fdc8b211651!2s14044+Magnolia+St+STE+%23227-228%2C+Westminster%2C+CA+92683!5e0!3m2!1sen!2sus!4v1548803421157" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div> */}
        <div className="row mb-5">
          <div className="col-md-4 contact-info">
            <h6><strong>Administrative</strong></h6>
            <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/bD6dyksdoRE2" rel="noopener noreferrer" target="_blank">12856 Brookhurst St, Garden Grove, CA 92840 USA</a></p>
            <p><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Sun: 9:00am - 6:00pm</p>
            <p><FaPhone className="fa fa-phone"></FaPhone> <a href="tel:714-895-8865">714-895-8865</a></p>
            <p><FaEnvelope className="fa fa-envelope"></FaEnvelope> <a href="mailto:contact@contegospa.com">contact@contegospa.com</a></p>            
          </div>
          <div className="col-md-8">
            <iframe className="map" title="Map of Contego Warehouse" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6632.770928645176!2d-117.958493!3d33.7765428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd287114aed349%3A0x4d7b69851c038792!2s12856+Brookhurst+St%2C+Garden+Grove%2C+CA+92840!5e0!3m2!1sen!2sus!4v1548802984792" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-4 contact-info">
            <h6><strong>ShowRoom - Garden Grove</strong></h6>
            <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/bD6dyksdoRE2" rel="noopener noreferrer" target="_blank">12856 Brookhurst St, Garden Grove, CA 92840 USA</a></p>
            <p><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Sun: 9:00am - 6:00pm</p>
            <p><FaPhone className="fa fa-phone"></FaPhone> <a href="tel:714-895-8865">714-895-8865</a></p>
            <p><FaEnvelope className="fa fa-envelope"></FaEnvelope> <a href="mailto:contact@contegospa.com">contact@contegospa.com</a></p>            
          </div>
          <div className="col-md-8">
            <iframe className="map" title="Map of Contego Showroom - Garden Grove" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6632.770928645176!2d-117.958493!3d33.7765428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd287114aed349%3A0x4d7b69851c038792!2s12856+Brookhurst+St%2C+Garden+Grove%2C+CA+92840!5e0!3m2!1sen!2sus!4v1548802984792" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-4 contact-info">
            <h6><strong>ShowRoom - Houston</strong></h6>
            <p><FaMapMarker className="fa fa-map-marker"></FaMapMarker> <a href="https://goo.gl/maps/mJtYPcC76pT2" rel="noopener noreferrer" target="_blank">6787A Wilcrest Dr, Houston, TX 77072 USA</a></p>
            <p style={{marginBottom: "0px"}}><FaClockO className="fa fa-clock-o"></FaClockO> Mon - Thur: 10:00am - 5:00pm</p>
            <p><FaClockO className="fa fa-clock-o"></FaClockO> Sun: 1:00pm - 6:00pm</p>
            <p><FaPhone className="fa fa-phone"></FaPhone> <a href="tel:714-895-8865">714-895-8865</a></p>
            <p><FaEnvelope className="fa fa-envelope"></FaEnvelope> <a href="mailto:contact@contegospa.com">contact@contegospa.com</a></p>            
          </div>
          <div className="col-md-8">
            <iframe className="map" title="Map of Contego Showroom - Houston" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55446.952348944535!2d-95.57599098899027!3d29.707170152605194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd96ba00d563e7808!2sCONTEGO+SPA+DESIGNS+Houston+Showroom!5e0!3m2!1sen!2sus!4v1553558565339" frameBorder="0" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;