import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { cartReducer } from 'react-shopping-cart';

import Accessories from './reducer_accessories';
import AccessoriesSelection from './reducer_accessories_selection';
import BaseColors from './reducer_basecolors';
import BowlColors from './reducer_bowlcolors';
import ChairColors from './reducer_chaircolors';
import Chairs from './reducer_chairs';
import Selection from './reducer_selection';

import Auth from './reducer_auth';


const rootReducer = combineReducers({
  accessories: Accessories,
  accessoriesSelection: AccessoriesSelection,
  basecolors: BaseColors,
  bowlcolors: BowlColors,
  chaircolors: ChairColors,
  chairs: Chairs,
  selection: Selection,
  auth: Auth,
  form: formReducer,
  cart: cartReducer
});

export default rootReducer;