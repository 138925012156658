import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import './thankyou.css';

const Thankyou = () => {
  return (
    <section id="thankyou">
      <div className="container">
        <h1>Thank you for your quote request!</h1>
        <h2>We will be in contact with you shortly.</h2>
        <Link to="/" id="thankyou-btn" class="btn btn-primary">Click Here To Return To Homepage</Link>
      </div>
    </section>
  );
}

export default Thankyou;