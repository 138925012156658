import React from 'react';
import ModalImage from 'react-modal-image';

import './patent.css'

import patent1 from './images/patent1.png';
import patent2 from './images/patent2.png';
import patent3 from './images/patent3.png';
import patent1lg from './images/patent1lg.png';
import patent2lg from './images/patent2lg.png';
import patent3lg from './images/patent3lg.png';

const Patent = () => {
  return (
    <section id="patent">
      <div className="container">
        <h1 className="patent-head">Patented Technology</h1>
        <div className="row">
          <div className="col-md-4">
            <h6 className="patent-title">Method & Apparatus for Disposable Spa Chair Pedicure Basin Sanitation</h6>
            <h6 className="patent-number">Patent No.: US 7,950,079 B2</h6>
            <ModalImage small={patent1} large={patent1lg} alt="METHOD & APPARATUS FOR DISPOSABLE SPA CHAIR PEDICURE BASIN SANITATION  Patent No.: US 7,950,079 B2" />
          </div>
          <div className="col-md-4">
            <h6 className="patent-title">Pedicure Basin w/ Overflow Protection</h6>
            <h6 className="patent-number">Patent No.: US 9,289,353 B2</h6>
            <ModalImage small={patent2} large={patent2lg} alt="PEDICURE BASIN W/ OVERFLOW PROTECTION  Patent No.: US 9,289,353 B2" />
          </div>
          <div className="col-md-4">
            <h6 className="patent-title">Liquid Container with Replaceable Liner</h6>
            <h6 className="patent-number">Patent No.: US 9,439,831 B2</h6>
            <ModalImage small={patent3} large={patent3lg} alt="LIQUID CONTAINER WITH REPLACEABLE LINER  Patent No.: US 9,439,831 B2" />
          </div>          
        </div>
      </div>
    </section>
  );
}

export default Patent;