import React from 'react';

import './features.css';
// import feat1 from './images/overflow-RGB.jpg';
// import feat2 from './images/light-RGB.jpg';
// import feat3 from './images/footrest-RGB.jpg';
// import feat4 from './images/showerheads-RGB.jpg';
// import feat5 from './images/pullhandle-RGB.jpg';
// import feat6 from './images/tray-RGB.jpg';

const Features = () => {
  return (
    <section id="features">
      <div className="container-fluid">
        <div className="row">
          <div id="feat1" className="col-md-6 feature-box">
            <div className="overlay">
              <h2 className="feat-title">Patented Infinity Overflow<sup>&trade;</sup></h2>
              <p className="feat-subtitle">Overflows into a drain, never on the ground - no sensor required</p>
            </div>
          </div>
          <div id="feat2" className="col-md-6 feature-box">
            <div className="overlay">
              <h2 className="feat-title">7-colored led basin light</h2>
              <p className="feat-subtitle">includes red, orange, yellow, green, blue, violet, and pink</p>
            </div>
          </div>
          <div id="feat3" className="col-md-6 feature-box">
            <div className="overlay">
              <h2 className="feat-title">adjustable footrests</h2>
              <p className="feat-subtitle">100in. stainless steel hardware adjusts in length and height</p>
            </div>
          </div>
          <div id="feat4" className="col-md-6 feature-box">
            <div className="overlay">
              <h2 className="feat-title">dual-function showerhead</h2>
              <p className="feat-subtitle">narrow or wide-spread stream</p>
            </div>
          </div>
          <div id="feat5" className="col-md-6 feature-box">
            <div className="overlay">
              <h2 className="feat-title">patented easydrain<sup>&trade;</sup></h2>
              <p className="feat-subtitle">Drain the basin without touching the water</p>
            </div>
          </div>
          <div id="feat6" className="col-md-6 feature-box">
            <div className="overlay">
              <h2 className="feat-title">armrest & tray table</h2>
              <p className="feat-subtitle">foldable tray with 100lb. weight capacity</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;