import React from 'react';

import Herovid from './herovid/herovid';

import './hero.css';

import magna from './images/MAGNA-Gold.png';

const Hero = () => {
  return (
    <section id="hero-wrapper">
      <div id="hero">
        <div id="hero-container" className="container">
          <h1 className="hero-line">Contégo Spa Designs</h1>
          <p className="lead">The world's only Disposable Pedicure Jet<sup>&trade;</sup></p>
          <img id="hero-img" src={magna} alt={magna} width="374" rel="preload" as="image"  />
        </div>
      </div>
      <Herovid />
    </section>
  );
}

export default Hero;