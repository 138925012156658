import React from 'react';

import './videosfp.css';

const Videosfp = () => {
  return (
    <section id="videosfp">
      <div className="container-fluid">
      <h1 className="videofp-header">English</h1>
        <div className="row">
          <div id="videofp1" className="col-md-6 videofp-box">
            <iframe className="videofp-item" title="Contego Spa Designs Intro To JetLiner™ For Pedicure Spa Chair" src="https://www.youtube.com/embed/-DDksqVPw1U" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
          <div id="videofp3" className="col-md-6 videofp-box">
            <iframe className="videofp-item" title="IPA Approves Contego Chairs" src="https://www.youtube.com/embed/CIhTNawXC7U?rel=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Videosfp;