import React, { PureComponent } from 'react';
import {
  Cart,
  Product,
  CheckoutButton,
  cartLocalization,
  // cartReducer,
  // setCartCurrency,
} from 'react-shopping-cart';
import { connect } from 'react-redux';
import { cartActions, cartActionTypes } from 'react-shopping-cart';

// console.log(cartLocalization);
const { getDefaultLocalization } = cartLocalization;
// console.log(cartLocalization);
// console.log(cartLocalization.getDefaultLocalization);

const jetLinerLocalization = {
  color: 'Color',
  jetLiner: 'Jet-Liner',
  red: 'Red',
  green: 'Green',
  yellow: 'Yellow',
  GBP: '£',
  EUR: '€',
  USD: '$',
  quantity: 3,
  prices: 190,
};
 
// const iPadPropertiesWithAdditionalCostLocalization = {
//   yellow: `Yellow (+{cost}{localizedCurrency})`,
// };

class ShoppingCart extends PureComponent {

  state = {
    product: {
      name: 'jetLiner',
      id: 'jet-liner',
      quantity: 3,
      path: '/shop/jet-liner/',
      properties: {
        color: ['red', 'green', {
          additionalCost: {
            GBP: 1,
            EUR: 2,
            USD: 3.50,
          },
          value: 'yellow',
        }],
      },
      propertiesToShowInCart: ['color'],
      prices: { GBP: 70, EUR: 80, USD: 90 },
      currency: 'USD',
      imageSrc: '1-483x321.jpeg',
    },
    getProductLocalization:
      getDefaultLocalization(
        'product',
        'en',
        {
          ...jetLinerLocalization,
          // ...iPadPropertiesWithAdditionalCostLocalization
        }
      ),
    getCheckoutButtonLocalization:
      getDefaultLocalization(
        'checkoutButton',
        'en',
        jetLinerLocalization,
      ),
    getCartLocalization:
      getDefaultLocalization(
        'cart',
        'en',
        jetLinerLocalization
      )
  };
 
  render() {
 
    const {
      product,
      getCheckoutButtonLocalization,
      getProductLocalization,
      getCartLocalization,
    } = this.state;
    // console.log(this.state);
    // console.log(this.props);

    const checkoutButtonElement =
      <CheckoutButton
        getLocalization={
          getCheckoutButtonLocalization
        }
        checkoutURL="/to/my/checkout"
      />;
    return (
        <div className="container">
          <Product
            {...product}
            checkoutButton={checkoutButtonElement}
            getLocalization={
              getProductLocalization
            }
 
          />
          <Cart
            checkoutButton={checkoutButtonElement}
            getLocalization={
              getCartLocalization
            }
          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    cart: state.cart
  };
}

export default connect(mapStateToProps, { cartActions, cartActionTypes })(ShoppingCart);