export const CHAIR_COLOR_CLICK = "CHAIR_COLOR_CLICK";

export default function onChairColorClick(chaircolor, chair, angle, bowlcolor, basecolor, msrp) {
  let chairURL = 'images/SpaChair/' + chair + '/medium/' + angle + '/' + chair + '-' + chaircolor + '-' + bowlcolor + basecolor + '.png';
  return {
    type: CHAIR_COLOR_CLICK,
    payload: {
      product: chair,
      angle: angle,
      chaircolor: chaircolor,
      bowlcolor: bowlcolor,
      basecolor: basecolor,
      pictureURL: chairURL,
      msrp: msrp
    }
  };
}