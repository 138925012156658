import React, { Component } from 'react';
import Delay from 'react-delay';

import './herovid.css';

class Herovid extends Component {

  render() {
    return (
      <Delay wait={5000}>
        <iframe id="herovid" title="Contego Intro Video" src="https://www.youtube.com/embed/UgweYkPh1jU?rel=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
      </Delay>
    );
  }
}

export default Herovid;