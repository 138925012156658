import React from 'react';
import { FaFacebook, FaYoutube, FaInstagram, FaYelp } from 'react-icons/lib/fa';

import './socialmedia.css';

const Socialmedia = () => {
  return (
    <div className="social-btns-side">
      <a className="btn facebook" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/contegospa/"><FaFacebook className="fa fa-facebook" /></a>
      <a className="btn youtube" rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UC7kqMnIleOcvV76jwmNb7Rg"><FaYoutube className="fa fa-youtube"></FaYoutube></a>
      <a className="btn instagram" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/contegospa/"><FaInstagram className="fa fa-instagram"></FaInstagram></a>
      <a className="btn yelp" rel="noopener noreferrer" target="_blank" href="https://www.yelp.com/biz/contego-spa-designs-westminster"><FaYelp className="fa fa-yelp"></FaYelp></a>
    </div>
  );
}

export default Socialmedia;