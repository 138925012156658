import React from 'react';

import './howto.css';

import step0 from './images/step0.jpg';
import step1 from './images/step1.jpg';
import step2 from './images/step2.jpg';
import step3 from './images/step3.jpg';
import step4 from './images/step4.jpg';
import step5 from './images/step5.jpg';


const Howto = () => {
  return (
    <section id="howto">
      <div className="container">
        <h1 className="howto-header">How To Use The Disposable Pedicure Jet<sup>&trade;</sup></h1>
        <div className="row">
          <div id="step0" className="col-md-4 howto-box">
            <div className="overlay">
              <img className="img-fluid step-img" src={step0} alt={step0} />
              <h2 className="howto-title">Disposable Pedicure Jet<sup>&trade;</sup></h2>
              <p className="invisible">Disposable</p>
            </div>
          </div>
          <div id="step1" className="col-md-4 howto-box">
            <div className="overlay">
              <img className="img-fluid step-img" src={step1} alt={step1} />
              <h2 className="howto-title">Step 1</h2>
              <p className="howto-subtitle">Place Disposable Pedicure Jet<sup>&trade;</sup> into basin.</p>
            </div>
          </div>
          <div id="step2" className="col-md-4 howto-box">
            <div className="overlay">
              <img className="img-fluid step-img" src={step2} alt={step2} />
              <h2 className="howto-title">Step 2</h2>
              <p className="howto-subtitle">Insert both pedicure jet tubes into nozzels.</p>
            </div>
          </div>
          <div id="step3" className="col-md-4 howto-box">
            <div className="overlay">
              <img className="img-fluid step-img" src={step3} alt={step3} />
              <h2 className="howto-title">Step 3</h2>
              <p className="howto-subtitle">Fill basin with water.</p>
            </div>
          </div>
          <div id="step4" className="col-md-4 howto-box">
            <div className="overlay">
              <img className="img-fluid step-img" src={step4} alt={step4} />
              <h2 className="howto-title">Step 4</h2>
              <p className="howto-subtitle">Service Client.</p>
            </div>
          </div>
          <div id="step5" className="col-md-4 howto-box">
            <div className="overlay">
              <img className="img-fluid step-img" src={step5} alt={step5} />
              <h2 className="howto-title">Step 5</h2>
              <p className="howto-subtitle">Dispose of both pedicure jet tubes and liner.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Howto;