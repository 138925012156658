import React from 'react';

import './about.css';

import Jet from './jet/jet';
import Patent from './patent/patent';
import Howto from './howto/howto';

const About = () => {
  return (
    <section id="about">
      <Jet />
      <Patent />
      <Howto />
    </section>
  );
}

export default About;