import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import './faq.css';

class Faq extends Component {

  render() {
    return (
      <section id="faq">
        <h1 id="faq-title">FAQ</h1>
        <div id="faq-toc" className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <h4 className="faq-toc-headers">General Questions</h4>
              <ul>
                <Link smooth to="#gqheading1">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#gqcollapse1" aria-expanded="true" aria-controls="gqcollapse1">
                    What kind of payment is accepted?
                    </button>
                  </li>
                </Link>
                <Link smooth to="#gqheading2">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#gqcollapse2" aria-expanded="false" aria-controls="gqcollapse2">
                      What type of drain system do I need?
                    </button>                
                  </li>
                </Link>
                <Link smooth to="#gqheading3">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#gqcollapse3" aria-expanded="false" aria-controls="gqcollapse3">
                      What type of electrical system do I need?
                    </button>
                  </li>
                </Link>
                <Link smooth to="#gqheading4">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#gqcollapse4" aria-expanded="false" aria-controls="gqcollapse4">
                      What is included with the purchase of the pedi-spa chair?
                    </button>
                  </li>
                </Link>
                <Link smooth to="#gqheading5">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#gqcollapse5" aria-expanded="false" aria-controls="gqcollapse5">
                      Do you offer a warranty on the spa chair?
                    </button>
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-md-4">
              <h4 className="faq-toc-headers">Pedi-Spa Chair Questions</h4>
              <ul>
                <Link smooth to="#pqheading1">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#pqcollapse1" aria-expanded="true" aria-controls="pqcollapse1">
                      What are the dimensions of the pedi-spa chair?
                    </button>                    
                  </li>
                </Link>
                <Link smooth to="#pqheading2">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#pqcollapse2" aria-expanded="false" aria-controls="pqcollapse2">
                      What is the difference between your pedicure jet system and the rest on the market?
                    </button>                    
                  </li>
                </Link>
                <Link smooth to="#pqheading3">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#pqcollapse3" aria-expanded="false" aria-controls="pqcollapse3">
                      What are the cleaning procedures for the Novo pedi-spa chair?
                    </button>                    
                  </li>
                </Link>
                <Link smooth to="#pqheading4">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#pqcollapse4" aria-expanded="false" aria-controls="pqcollapse4">
                      Can I use Disposable Pedicure Jet™ on a spa chair of another brand?
                    </button>                    
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-md-4">
              <h4 className="faq-toc-headers">Shipping Questions</h4>
              <ul>
                <Link smooth to="#sqheading1">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#sqcollapse1" aria-expanded="true" aria-controls="sqcollapse1">
                      I live outside of the USA; can you ship to me?
                    </button>                    
                  </li>
                </Link>
                <Link smooth to="#sqheading2">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#sqcollapse2" aria-expanded="false" aria-controls="sqcollapse2">
                      I live outside of the USA; will this pedi-spa chair be compatible with my electrical system?
                    </button>                    
                  </li>
                </Link>
                <Link smooth to="#sqheading3">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#sqcollapse3" aria-expanded="false" aria-controls="sqcollapse3">
                      How do I unpack the shipment and set up the pedi-spa chair?
                    </button>                    
                  </li>
                </Link>
                <Link smooth to="#sqheading4">
                  <li>
                    <button className="btn btn-link collapsed toc-btn-custom" type="button" data-toggle="collapse" data-target="#sqcollapse4" aria-expanded="false" aria-controls="sqcollapse4">
                      What if my order shipment arrives with damages on the box?
                    </button>                    
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        <div className="accordion" id="accordion">

          <div className="card">
            <div className="card-header" id="gqheading1">
              <h5 className="mb-0">
                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#gqcollapse1" aria-expanded="true" aria-controls="gqcollapse1">
                  What kind of payment is accepted?
                </button>
              </h5>
            </div>
            <div id="gqcollapse1" className="collapse" aria-labelledby="gqheading1" data-parent="#accordion">
              <div className="card-body">
                We accept Cash, Checks (Personal, Business, Cashier), and Credit Cards (Visa, American Express, Discover, MasterCard).
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="gqheading2">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#gqcollapse2" aria-expanded="false" aria-controls="gqcollapse2">
                  What type of drain system do I need?
                </button>
              </h5>
            </div>
            <div id="gqcollapse2" className="collapse" aria-labelledby="gqheading2" data-parent="#accordion">
              <div className="card-body">
                Please refer to the <a href="https://www.dropbox.com/s/zj7vq137di7jtgf/Chair%20Specs.pdf?dl=0" rel="noopener noreferrer" target="_blank" className="faq-link">Novo Pedi-Spa Chair Specifications</a> for drain system details. If you need assistance, please contact us.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="gqheading3">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#gqcollapse3" aria-expanded="false" aria-controls="gqcollapse3">
                  What type of electrical system do I need?
                </button>
              </h5>
            </div>
            <div id="gqcollapse3" className="collapse" aria-labelledby="gqheading3" data-parent="#accordion">
              <div className="card-body">
                Please refer to the <a href="https://www.dropbox.com/s/zj7vq137di7jtgf/Chair%20Specs.pdf?dl=0" rel="noopener noreferrer" target="_blank" className="faq-link">Novo Pedi-Spa Chair Specifications</a> for electrical system details. If you need assistance, please contact us.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="gqheading4">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#gqcollapse4" aria-expanded="false" aria-controls="gqcollapse4">
                  What is included with the purchase of the pedi-spa chair?
                </button>
              </h5>
            </div>
            <div id="gqcollapse4" className="collapse" aria-labelledby="gqheading4" data-parent="#accordion">
              <div className="card-body">
                For every pedi-spa chair that you purchase, you will also receive a <strong>Pedi Stool</strong>. With each order, you will also receive a <strong>Marketing Kit</strong>, that will assist in informing clients about your pedi-spa chair with a Disposable Pedicure Jet™ system to help keep them safe. The Marketing Kit includes: a beautiful 24x36” <strong>Salon Poster</strong>, 8.5x11” laminated <strong>Handouts</strong> customized with your salon name and/or logo, and (for California residents) an 11x17” <strong>Poster</strong> on the official California Board of Barbering and Cosmetology Health and Safety Regulations.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="gqheading5">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#gqcollapse5" aria-expanded="false" aria-controls="gqcollapse5">
                  Do you offer a warranty on the spa chair?
                </button>
              </h5>
            </div>
            <div id="gqcollapse5" className="collapse" aria-labelledby="gqheading5" data-parent="#accordion">
              <div className="card-body">
                We offer a 1-Year Warranty on the pedi-spa chair, and a 3-Year Warranty on the pedicure jet pump. Please contact us for details.
              </div>
            </div>
          </div>        

          <div className="card">
            <div className="card-header" id="pqheading1">
              <h5 className="mb-0">
                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#pqcollapse1" aria-expanded="true" aria-controls="pqcollapse1">
                  What are the dimensions of the pedi-spa chair?
                </button>
              </h5>
            </div>
            <div id="pqcollapse1" className="collapse" aria-labelledby="pqheading1" data-parent="#accordion">
              <div className="card-body">
                Please refer to the <a href="https://www.dropbox.com/s/zj7vq137di7jtgf/Chair%20Specs.pdf?dl=0" rel="noopener noreferrer" target="_blank" className="faq-link">Novo Pedi-Spa Chair Specifications</a> for dimensions. If you need assistance, please contact us.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="pqheading2">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#pqcollapse2" aria-expanded="false" aria-controls="pqcollapse2">
                  What is the difference between your pedicure jet system and the rest on the market?
                </button>
              </h5>
            </div>
            <div id="pqcollapse2" className="collapse" aria-labelledby="pqheading2" data-parent="#accordion">
              <div className="card-body">
                Traditional pedicure jet systems are reusable and need to cleaned and sanitized after each service, and if not done properly, could result in cross-contamination and infection. Contégo pedi-spa chairs have a disposable foot spa system called <strong>Disposable Pedicure Jet™</strong>, that helps protect each and every client from cross-contamination. Pedicure jet tubes are embedded into the liner to provide the foot massage. After the service is complete, both the liner and pedicure jet tubes are disposed of, and every NEW service begins with a NEW Disposable Pedicure Jet™. Not only do you save time from cleaning reusable jets, but you also save money on all the costly chemicals you would have to use!
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="pqheading3">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#pqcollapse3" aria-expanded="false" aria-controls="pqcollapse3">
                  What are the cleaning procedures for the Novo pedi-spa chair?
                </button>
              </h5>
            </div>
            <div id="pqcollapse3" className="collapse" aria-labelledby="pqheading3" data-parent="#accordion">
              <div className="card-body">
                Please review laws in your area for cleaning procedures. Contégo pedi-spa chairs are compliant with the <a href="http://www.barbercosmo.ca.gov/laws_regs/art12.shtml#a980_4" rel="noopener noreferrer" target="_blank" className="faq-link">California Board of Barbering and Cosmetology Health and Safety Regulations: Title 16, Division 9, Section 980.4 Disposable Basin or Tub Liners</a>, which states: 
                <ul>
                  <li>(a) Single use, disposable, recyclable, liners designed specifically and manufactured for use as a foot basin or tub liner shall be disposed of immediately after each use and may not be disinfected or reused.</li>
                  <li>(1) After disposal of the pedicure basin liner the basin or tub shall be scrubbed and cleaned of all visible debris with a clean brush and liquid soap (labeled as such on soap product) and water. The foot basin or tub shall be rinsed with clean water and wiped dry with a new, clean paper towel.</li>
                  <li>(2) Record the cleaning procedure in the pedicure equipment – cleaning log. The log shall contain the date and time of each cleaning, initials of the person who completed the procedure, and shall indicate that the cleaning was done after a client.</li>
                  <li>(3) The pedicure equipment-cleaning log shall be made available upon request by either a client or a board representative.</li>
                  <li>(4) Establishments or schools that utilize the liners must maintain a supply of five (5) liners per foot tub basin for use at all times.</li>
                </ul>
                Authority cited: Sections 7312 and 7406, Business and Professions Code. Reference: Section 7312(e), Business and Professions Code.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="pqheading4">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#pqcollapse4" aria-expanded="false" aria-controls="pqcollapse4">
                  Can I use Disposable Pedicure Jet™ on a spa chair of another brand?
                </button>
              </h5>
            </div>
            <div id="pqcollapse4" className="collapse" aria-labelledby="pqheading4" data-parent="#accordion">
              <div className="card-body">
                The Disposable Pedicure Jet™ is patented by Contégo Spa Designs, Inc., and therefore, Contégo pedi-spa chairs are the ONLY spa chairs that are compatible with this pedicure jet system.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="sqheading1">
              <h5 className="mb-0">
                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#sqcollapse1" aria-expanded="true" aria-controls="sqcollapse1">
                  I live outside of the USA; can you ship to me?
                </button>
              </h5>
            </div>
            <div id="sqcollapse1" className="collapse" aria-labelledby="sqheading1" data-parent="#accordion">
              <div className="card-body">
                We have shipped internationally to Saudi Arabia, Australia, Malta, and Canada. Please contact us for details on shipping to your area.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="sqheading2">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#sqcollapse2" aria-expanded="false" aria-controls="sqcollapse2">
                  I live outside of the USA; will this pedi-spa chair be compatible with my electrical system?
                </button>
              </h5>
            </div>
            <div id="sqcollapse2" className="collapse" aria-labelledby="sqheading2" data-parent="#accordion">
              <div className="card-body">
                We are able to adjust the pedi-spa chair electrical system to meet your individual needs; please contact us for more information.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="sqheading3">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#sqcollapse3" aria-expanded="false" aria-controls="sqcollapse3">
                  How do I unpack the shipment and set up the pedi-spa chair?
                </button>
              </h5>
            </div>
            <div id="sqcollapse3" className="collapse" aria-labelledby="sqheading3" data-parent="#accordion">
              <div className="card-body">
                Please refer to the <a href="https://www.dropbox.com/s/qkeury1fsqhoitq/Unpacking%26HookupProcedure.pdf?dl=0" rel="noopener noreferrer" target="_blank" className="faq-link">Unpacking and Hookup Procedures</a> booklet for instructions on unpacking shipments and pedi-spa chair hookup.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="sqheading4">
              <h5 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#sqcollapse4" aria-expanded="false" aria-controls="sqcollapse4">
                  What if my order shipment arrives with damages on the box?
                </button>
              </h5>
            </div>
            <div id="sqcollapse4" className="collapse" aria-labelledby="sqheading4" data-parent="#accordion">
              <div className="card-body">
                Refuse the shipment, or state on signed paper, if there are any visible damages to the shipment. Transportation damages must be claimed within Forty-Eight (48) hours after receiving the products by contacting customer support at Contégo.
              </div>
            </div>
          </div>        

        </div>
      </section>
    );
  }
}

export default Faq;