import React from 'react';

import './vs.css';

import contegovs from './images/vs-small.jpg';

const Vs = () => {
  return (
    <section id="vs">
      <div className="container">
        <h1 className="vs-title">Our Patented Technology</h1>
        <h3 className="vs-subtitle">The World's Only Disposable Pedicure Jet<sup>&trade;</sup></h3>
        <img className="img-fluid" src={contegovs} alt={contegovs} />
      </div>
    </section>
  );
}

export default Vs;